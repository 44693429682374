import React from "react"

import $ from "jquery"

import { Splide, SplideSlide } from "@splidejs/react-splide"

import "@splidejs/splide/dist/css/themes/splide-default.min.css"
import "@splidejs/splide/dist/css/themes/splide-skyblue.min.css"
import "@splidejs/splide/dist/css/themes/splide-sea-green.min.css"

import "./partners.css"

import Translate from "react-translate-component"

class Partners extends React.Component {
  // Render logos
  renderLogos() {
    var logos = [
      "4seasons.png",
      "vision.png",
      "espreso.png",
      "ihg.png",
      "Holiday_Inn.png",
      "marriott.png",
      "upm.png",
      "super8.svg",
      "movenpick.svg",
      "theredsea.svg",
      "EHL.png",
    ]

    var path = "./images/partners/"
    var src = ""
    var all = []
    for (var i in logos) {
      src = path + logos[i]
      all.push(
        <SplideSlide>
          <img src={src} alt="" />
        </SplideSlide>
      )
    }
    return all
  }

  render() {
    return (
      <section className="partners show-on-scroll appear-anim">
        <Translate
          content="partners.title"
          component="h1"
          className="partners__title"
        />

        <div className="partners__container">
          {/* Carousel */}
          <article className="partners__logos">
            <Splide
              options={{
                type: "loop",
                perPage: 5,
                perMove: 1,
                breakpoints: {
                  1024: {
                    perPage: 3,
                  },
                  767: {
                    perPage: 2,
                  },
                  640: {
                    perPage: 1,
                  },
                },
              }}
              className="show-on-scroll"
            >
              {this.renderLogos()}
            </Splide>
          </article>

          {/* <article>
            <h3 className="partners__container__title">AEFE</h3>

            <p className="partners__container__text">
              Lorem ipsum dolor, sit amet consectetur adipisicing elit. Maiores,
              facere quos laboriosam amet placeat iste obcaecati eius est? Ex
              dolores explicabo aliquam maiores tempora voluptatum harum quasi
              nemo minima nisi. \n rem ipsum dolor sit amet consectetur
              adipisicing elit. Repellat hic est praesentium omnis cumque
              voluptates optio perspiciatis, delectus iusto illo, a nulla vero
              quas, unde pariatur ipsa obcaecati dolor incidunt.
            </p>
          </article> */}
        </div>
      </section>
    )
  }
}

export default Partners

// <!-- <div className="partners__row partners__row-1 show-on-scroll">

//           <span className="arrow left" id="partners-arrow-js"></span>

//           <div>

//             <img src="images/partners/colored-logo.png" alt=""/>

//             <button className="learn-more" id="aefe">Learn more</button>
//           </div>

//           <div>

//             <img src="images/partners/EHL_Logo.png" alt=""/>

//             <button className="learn-more" id="aft">Learn more</button>
//           </div>

//           <div>

//             <img src="images/partners/4seasons.png" alt=""/>

//             <button className="learn-more" id="fm2">Learn more</button>
//           </div>

//           <div>

//             <img src="images/partners/holiday-inn_lsc_lkp_r_rgb_pos_fr-web.png" alt=""/>

//             <button className="learn-more" id="aft">Learn more</button>
//           </div>

//           <div>

//             <img src="images/partners/ihg-logo-nav.png" alt=""/>

//             <button className="learn-more" id="aft">Learn more</button>
//           </div>

//           <span className="arrow right" id="partners-arrow-js"></span>
//         </div> -->

//         <!-- Row 2 -->
//         <!-- <div>

//           <img src="images/partners/logo_1x.png" alt=""/>

//           <button className="learn-more" id="aft">Learn more</button>
//         </div> -->
//         <!-- <div className="partners__row partners__row-2">
//           <div>

//             <img src="images/partners/logo.png" alt=""/>

//             <button className="learn-more" id="aft">Learn more</button>
//           </div>

//           <div>

//             <img src="images/partners/marriott.png" alt=""/>

//             <button className="learn-more" id="aft">Learn more</button>
//           </div>
//         </div> -->
