import React, { Component } from "react"
// import anime from "animejs/lib/anime.es.js"
import "./hero.css"

import PopUs from "../subPopUp/PopUp"

import Translate from "react-translate-component"

class Hero extends Component {

  componentDidMount() {
    var slideIndex = 0
    showSlides()

    function showSlides() {
      var i
      var slides = document.getElementsByClassName("mySlides")
      for (i = 0; i < slides.length; i++) {
        slides[i].style.display = "none"
      }
      slideIndex++
      if (slideIndex > slides.length) {
        slideIndex = 1
      }
      slides[slideIndex - 1].style.display = "block"
      setTimeout(showSlides, 5000) // Change image every 2 seconds
    }
  }

  render() {
    return (
      <div className="hero">
        <Translate
          content="hero.title"
          component="h1"
          className="hero__title"
        />
        <Translate
          content="hero.sub_title"
          component="h2"
          className="hero__subTitle"
        />
        <Translate
          content="hero.contact"
          component="a"
          className="contact_us"
          id="click-me"
          href="mailto:Contact@NasrConsulting.com"
        />
      </div>
    )
  }
}

export default Hero
