import React from 'react'
import Translate from "react-translate-component"


class ApplyForm extends React.Component {
  render() { 
    return (
      <>
        <form
          className="applyForm"
          id="apply"
          action="phpScripts/apply.php"
          method="post"
          enctype="multipart/form-data"
        >
          <h1 className="jobName-js text-left"></h1>
          <div class="form-group">
            <Translate
              content="career.firstName"
              component="label"
              for="exampleInputEmail1"
            />
            <input
              type="text"
              class="form-control"
              name="firstName"
              placeholder="Enter First Name"
              required
            />
          </div>
          <div class="form-group">
            <Translate
              content="career.lastName"
              component="label"
              for="exampleInputPassword1"
            />
            <input
              type="text"
              name="lastName"
              class="form-control"
              id="exampleInputPassword1"
              placeholder="Enter Last Name"
              required
            />
          </div>
          <div class="form-group">
            <label for="exampleFormControlFile1">Cv</label>
            <input
              type="file"
              name="file"
              class="form-control-file"
              id="exampleFormControlFile1"
              required
            />
          </div>
          <Translate
            content="career.submit"
            component="button"
            class="btn btn-primary"
            type="submit"
          />
          <div className="email mt-3">
            <Translate content="career.email" component="span" /> : <br />
            <a href="mailto:HR@nasrhospitality.com">HR@nasrhospitality.com</a>
          </div>
        </form>
      </>
    )
  }
}
 
export default ApplyForm;