import React from "react"

import Footer from "../../components/footer/Footer"
import Header from "../../components/header/Header"
import "./successPage.css"


class SuccessPage extends React.Component {

  state = {
  seconds : 5
}

  countdownTimer = () => {
    var self = this
    var foo = setInterval(_ => {
      self.setState({
        seconds: self.state.seconds - 1,
      })
      if (self.state.seconds == 0) {
        clearInterval(foo)
         document.location.href = "./"
      }
    }, 1000)
  }
  componentDidMount() {
    this.countdownTimer()
  }

  render() {
    return (
      <div className="success">
        <Header modifier="mod2" />
        <div className="successContainer">
          <h1 className="text-center">
            Your submit is <span className="text-success">Successful</span>{" "}
          </h1>
          <h3>You will be redirected in {this.state.seconds}</h3>
        </div>
        <Footer />
      </div>
    )
  }
}

export default SuccessPage
