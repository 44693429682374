import React from "react"
import "./services.css"

// Import component
import Header from "../../components/header/Header"
import Footer from "../../components/footer/Footer"
import ServicesCards from "../../components/services/ServicesCards"
import ServicesText from "./servicesText/ServicesText"

import observer from "../../jsScripts/observer"

// Translate
import counterpart from "counterpart"
import Translate from "react-translate-component"
import { GiLogicGateXor } from "react-icons/gi"
import CarouselArrow from "../../jsScripts/CarouselArrow"

class ServicesPage extends React.Component {
  componentDidMount() {
    observer()
  }

  onLangChange = (language) => {
    this.setState({ lang: language })
    counterpart.setLocale(language)
  }

  render() {
    const path = "images/services/"
    const cards = [
      [path + "recruit1.jpg", path + "recruit2.jpg"],
      [path + "education1.jpg", path + "education2.jpg"],
      [path + "concept1.jpg", path + "concept2.jpg"],
      [path + "project1.jpg", path + "project2.jpg"],
    ]


    const n = 4

    return (
      <main className="services">
        <Header fn={this.onLangChange} />

        <section class="our-services">
          <Translate
            content="services.title2"
            component="h1"
            className="mbvh-5 text-center display-4"
          />
          <div className="services__container">
            <Translate content="services.title" component="h2" />

            <Translate content="services.text" component="p" unsafe={true} />
          </div>

          {/* Cards */}
          <div class="service_cards_container show-on-scroll appear-anim mbvh-10">
            {cards.map((src, key) => (
              <a href={"#link" + key} class="service_cards_content">
                <Translate
                  content={"servicesCards" + key + ".card_title"}
                  component="h3"
                  className="margin-2"
                />
                <CarouselArrow urls={src} id={"service_carousel" + key} />
              </a>
            ))}
            {/* {sources.map((src, key) => (
              <ServicesCards id={key} src={src} />
            ))} */}

            {/* Text body */}
          </div>
          <div className="card_text_container">
            {[...Array(n)].map((elem, key) => (
              <ServicesText id={key} />
            ))}
          </div>
        </section>

        <Footer />
      </main>
    )
  }
}

export default ServicesPage

// const icons = ["user ,building", "handshake", "coins", "key"]
// var cards = ["servicesCards1", "servicesCards2", "servicesCards3", "servicesCards4"]
