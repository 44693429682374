import React from "react"
import "../text.css"

function TextImg() {
  return (
     <section class="section text-container text-container-image">

    {/* <div class="content__container">

      <h3 class="content__title">Title</h3>

      <p class="content__text"> Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic nulla ea, nisi placeat
        maxime aut veniam
        consectetur
        deleniti vero officiis esse rem debitis dolore ad neque vel repellat voluptatum nihil? <br/>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Facilis commodi nihil laudantium, fugit eligendi
        assumenda eaque molestias aliquam maxime unde non nulla, quam qui repudiandae sit, a veritatis ad molestiae.</p>
    </div> */}


  </section>
  )
}

export default TextImg
