import * as React from "react"
// import ReactMapGL, { Marker } from "react-map-gl"

import { Map, Marker } from "pigeon-maps"

import { FaMapMarkerAlt } from "react-icons/fa"

function MapComponent() {
  const [viewport, setViewport] = React.useState({
    latitude: 39.0,
    longitude: 11.7,
    zoom: 2,
  })

  const location = [
    {
      lat: 35.82,
      lng: 10.63,
    },
    {
      lat: 53.55,
      lng: 9.94,
    },
    {
      lat: 24.52,
      lng: 39.56,
    },
    {
      lat: 48.86,
      lng: 2.34,
    },
  ]

  return (
    // <ReactMapGL
    //   {...viewport}
    //   mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
    //   mapStyle="mapbox://styles/mapbox/streets-v11"
    //   width="100%"
    //   height="100%"
    //   onViewportChange={(viewport) => setViewport(viewport)}
    // >
    //   {location.map((elem, id) => (
    //     <Marker
    //       key={id}
    //       latitude={elem.lat}
    //       longitude={elem.lng}
    //       offsetLeft={-10}
    //       offsetTop={-10}
    //     >
    //       <FaMapMarkerAlt color="red" />
    //     </Marker>
    //   ))}
    // </ReactMapGL>

    <Map height={300} defaultCenter={[39.0, 11.7]} defaultZoom={3.5}>
      {location.map((elem) => (
        <Marker width={30} anchor={[elem.lat, elem.lng]} />
      ))}
    </Map>
  )
}

export default MapComponent
