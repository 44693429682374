import React from "react"
import "./footer.css"
import Map from "./map/Map"

import {
  AiOutlineTwitter,
  AiOutlineInstagram,
  AiOutlineWhatsApp,
  AiOutlineYoutube,
} from "react-icons/ai"
import { FaFacebookF, FaLinkedinIn } from "react-icons/fa"

// Translate
import Translate from "react-translate-component"

function Footer() {
  return (
    <footer class="footer">
      <Translate
        content="footer.title"
        component="h2"
        className="footer__contact-us__title title-2 "
      />

      {/* Contact places */}
      <p className="show-on-scroll slide-left-anim">
        <Translate content="footer.location1" component="strong" />
        <br />
        <a href="mailto:Contact@NasrConsulting.com">
          Contact@NasrConsulting.com
        </a>{" "}
        <br />
        +49 176 76740484 <br />
        <span class="green">
          Große Bergstraße 143 <br /> Hamburg 22767, Germany <br />
        </span>
      </p>
      <div class="footer__contact-us__container show-on-scroll slide-left-anim">
        <p>
          <Translate content="footer.location2" component="strong" />
          <br />{" "}
          <a href="mailto:Contact@NasrConsulting.com">
            Contact@NasrConsulting.com
          </a>
          <br /> +49 176 76740484 <br />
          <Translate
            content="footer.city2"
            component="span"
            className="green"
          />
        </p>

        <p>
          <Translate content="footer.location3" component="strong" /> <br />{" "}
          <a href="mailto:Contact@NasrConsulting.com">
            Contact@NasrConsulting.com
          </a>
          <br />
          <Translate
            content="footer.city3"
            component="span"
            className="green"
          />
        </p>

        <p>
          <Translate content="footer.location4" component="strong" /> <br />{" "}
          <a href="mailto:Contact@NasrConsulting.com">
            Contact@NasrConsulting.com
          </a>
          <br />
          +216 22536263 <br />
          <Translate
            content="footer.city4"
            component="span"
            className="green"
          />
        </p>
      </div>

      {/* Map */}
      <div className="map show-on-scroll appear-anim">
        <Map />
      </div>
      {/* Social medias */}
      <p class="footer__links show-on-scroll slideLeft slide-left-anim">
        <a
          target="_blank"
          href="https://www.linkedin.com/company/nasr-gmbh/about/"
        >
          <FaLinkedinIn className="linkedin" />
        </a>
        <a
          target="_blank"
          href="https://www.facebook.com/Nasr-Hospitality-Consulting-GmbH-103054332148227/"
        >
          <FaFacebookF className="facebook" />
        </a>
        <a target="_blank" href="https://twitter.com/NasrHospitality">
          <AiOutlineTwitter className="twitter" />
        </a>
        <a
          target="_blank"
          href="https://www.instagram.com/nasr_hospitality_consulting/?hl=fr"
        >
          <AiOutlineInstagram className="instagram" />
        </a>
        <a
          target="_blank"
          href="https://api.whatsapp.com/send?phone=49 17676740484"
        >
          <AiOutlineWhatsApp className="whatsapp" />
        </a>
        <a
          target="_blank"
          href="https://www.youtube.com/channel/UCJ6MYAJJWYCU8nbc4Y89-3Q"
        >
          <AiOutlineYoutube className="yt" />
        </a>
        <br /> Copyright © 2017-2022 Nasr Hospitality Consulting
      </p>
    </footer>
  )
}

export default Footer
