import React, { useState, useEffect } from "react"
import EmailForm from "./EmailForm"
import JsEmail from "../jsEmail/JsEmail"
import MailchimpSubscribe from "react-mailchimp-subscribe"

import "./contact.css"
import {
  AiOutlineTwitter,
  AiOutlineInstagram,
  AiOutlineWhatsApp,
} from "react-icons/ai"

import Translate from "react-translate-component"

const url = process.env.NEXT_PUBLIC_MAILCHIMP_URL

// simplest form (only email)
const SimpleForm = () => <MailchimpSubscribe url={url} />

// use the render prop and your custom form
const CustomForm = () => (
  <MailchimpSubscribe
    url={url}
    render={({ subscribe, status, message }) => (
      <div>
        <SimpleForm onSubmitted={(formData) => subscribe(formData)} />
        {status === "sending" && (
          <div style={{ color: "blue" }}>sending...</div>
        )}
        {status === "error" && (
          <div
            style={{ color: "red" }}
            dangerouslySetInnerHTML={{ __html: message }}
          />
        )}
        {status === "success" && (
          <div style={{ color: "green" }}>Subscribed !</div>
        )}
      </div>
    )}
  />
)

function Contact() {
  return (
    <section className="contact" id="contact">
      <EmailForm />
      {/* <JsEmail /> */}

      <div className="contact__whatsapp whatsapp">
        <Translate
          content="contact.whatsapp"
          component="h3"
          className="whatsapp"
        />
        <a href="https://api.whatsapp.com/send?phone=49 17676740484">
          <AiOutlineWhatsApp
            size="4rem"
            classNameName="whatsapp contact__whatsapp"
          />
        </a>
        <h3>WhatsApp</h3>
      </div>
      {/* {CustomForm()} */}
    </section>
  )
}

export default Contact
