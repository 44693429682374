import $ from "jquery"

function showOrHide(elem, instantHide) {
  var element = document.querySelector(elem)

  // Show
  if (element.classList.contains("box-hidden")) {
    element.classList.add("box-transition")
    var el = element.clientWidth
    element.classList.remove("box-hidden")
  } else {
    // Hide
    if (instantHide) {
      element.classList.add("box-transition")
    }
    element.classList.add("box-hidden")
  }

  element.addEventListener(
    "transitionend",
    function () {
      element.classList.remove("box-transition")
    },
    false
  )
}

export default showOrHide
