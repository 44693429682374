import React from "react"
import "./home.css"

// Translate
import Translate from "react-translate-component"
import Header from "../../../components/header/Header"
import Footer from "../../../components/footer/Footer"

class Home extends React.Component {
  render() {
    return (
      <>
        <Header modifier="mod2" />
        <div className="HomeCertificate">
          <div className="HomeCertificate_container">
            <img
              src="images/logo/Fichier 38.png"
              alt="NHC logo"
              className="logoAffiche"
            />
            <div className="d-grid gap-4">
              <Translate
                content="header.title"
                component="h1"
                className="display-4"
                unsafe={true}
              />
              <Translate
                content="overviewPage.subtitle"
                component="h2"
                className=""
                unsafe={true}
              />
              <Translate
                content="overviewPage.start"
                component="a"
                href="online_certificate"
                className="start"
                unsafe={true}
              />
            </div>
          </div>
        </div>
        <Footer />
      </>
    )
  }
}

export default Home
