import React from "react"
// import "../App.css"
import "./career.css"

import Header from "../../components/header/Header"
import Footer from "../../components/footer/Footer"

import observer from "../../jsScripts/observer"

import $ from "jquery"
import ApplyForm from "../../components/applyForm/ApplyForm"

// Translate
import counterpart from "counterpart"
import Translate from "react-translate-component"

import Job2 from "../../components/job/job2"

class Career extends React.Component {
  componentDidMount() {
    observer()

    $(".job-js").click(function () {
      // & fix
      var str = $(".card-title")[this.id].innerHTML
      if (str.includes("&amp;")) {
        str = str.replace("&amp;", "&")
      }

      $(".jobName-js").text("Apply for " + str)
    })
  }

  onLangChange = (language) => {
    this.setState({ lang: language })
    counterpart.setLocale(language)
  }

  // Render jobs
  renderJobs() {
    var all = []
    for (let i = 1; i < 6; i++) {
      all.push(<Job2 id={i} />)
    }

    return all
  }

  render() {
    return (
      <main className="career">
        <Header fn={this.onLangChange} modifier="mod2"/>
        <div className="career__container">
          <Translate content="career.title" component="h2" />
          <Translate content="career.text" component="p" />

          
          <section className="jobSection">
            {/* {this.renderJobs()} */}
            {Array.from({ length: 6 }, (_, i) => (
              <Job2 id={i} />
            ))}
          </section>

          <ApplyForm />
        </div>

        <Footer />
      </main>
    )
  }
}

export default Career
