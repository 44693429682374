/* ----------------------------- Align arabic text ----------------------------- */
import $ from 'jquery'
function arabicAlign(language) {
  if (language == "ar") {
    $(".root").css({
      "text-align": "right",
      direction: "rtl",
   })
    $("html").css({
      "font-size": "20px",
    })
  } else {
    $(".root").css({
      "text-align": "left",
      direction: "ltr",
    })
    $("html").css({
      "font-size": "16px",
    })
  }
}

export default arabicAlign
