import React, { Component } from "react"
import { unmountComponentAtNode, render } from "react-dom"

import $ from "jquery"

class CarouselArrow extends React.Component {
  componentDidMount() {
    if (this.props.interval) {
      var interval = this.props.interval
    } else var interval = 5000
    var slideIndex = 1
    var timeoutHandle
    var slides = document.getElementsByClassName(this.props.id + "_mySlides")
    newsShowSlides(slideIndex)

    $(".newsPrev").on("click", function () {
      plusSlides(-2)
    })
    $(".newsNext").on("click", function () {
      plusSlides(0)
    })

    $(".vid").on("click", function () {
      clearTimeout(timeoutHandle)
    })

    function plusSlides(n) {
      clearTimeout(timeoutHandle)
      newsShowSlides((slideIndex += n))
    }

    function newsShowSlides(n) {
      var i

      for (i = 0; i < slides.length; i++) {
        slides[i].style.display = "none"
      }

      if (slideIndex > slides.length) {
        slideIndex = 1
      }
      if (n < 1) {
        slideIndex = slides.length
      }

      slides[slideIndex - 1].style.display = "block"
      slideIndex++
      timeoutHandle = setTimeout(newsShowSlides, interval)
      // if (slideIndex == 9) {
      //   clearTimeout(timeoutHandle)
      // } else {
      // }
    }
  }

  render() {
    return (
      <div className={"carousel " + this.props.classes} id={this.props.id}>
        <div class="news_slideshow-container">
          {this.props.urls.map((url) => (
            <div class={this.props.id + "_mySlides newsFade"}>
              <img src={url} className="carouselImg" />
            </div>
          ))}

          <a class="newsPrev" onClick={"plusSlides(-1)"}>
            &#10094;
          </a>
          <a class="newsNext" onClick={"plusSlides(1)"}>
            &#10095;
          </a>
        </div>
      </div>
    )
  }
}

export default CarouselArrow
