import React, { Component } from "react"
import "./cardServices.css"

// Translate
import Translate from "react-translate-component"

class ServicesCards extends Component {
  render() {
    var x = this.props.id + 1

    return (
      <a href={"#link" + x} class="service-content">
        <Translate
          content={"servicesCards" + x + ".card_title"}
          component="h3"
          className="margin-2"
        />
        <img src={"./images/services/" + this.props.src} />
      </a>
    )
  }
}

export default ServicesCards



 {
   /* <i class={"fas fa-" + this.props.icon}></i> */
 }
 {
   /* <Translate
          content="services.card_learnMore"
          component="a"
          href={this.props.href}
        /> */
 }