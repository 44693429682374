import React, { useRef, useEffect } from "react"
import Header from "../../../../components/header/Header"
import Footer from "../../../../components/footer/Footer"
import "./payment.css"

import { useLocation } from "react-router-dom"


export default function Paypal() {
  const paypal = useRef()

  useEffect(() => {
    window.paypal
      .Buttons({
        createOrder: (data, actions, err) => {
          return actions.order.create({
            intent: "CAPTURE",
            purchase_units: [
              {
                description: 'Wiw',
                amount: {
                  currency_code: "USD",
                  value: "111.0",
                },
              },
            ],
          })
        },
        onApprove: async (data, actions) => {
          const order = await actions.order.capture()
          console.log("Approve Message", order)
        },
        onError: (err) => {
          console.log("Error Message", err)
        },
      })
      .render(paypal.current)
  }, [])


  return (
    <>
      <Header />
      <div className="payment" ref={paypal}></div>

      <div className="d-sm-flex flexcolumn justify-content-around text-center mb-5">
        <div>
          <h2>Commerzbank :</h2> <br /> <strong>IBAN : </strong>{" "}
          DE10200400000580298800 <br /> <strong>BIC : </strong>
          COBADEFFXXX
        </div>
        <div>
          <h2>N26 Bank :</h2>
          <br /> <strong>IBAN </strong>: DE35 1001 1001 2623 3629 23 <br />
          <strong>BIC : </strong>NTSBDEB1XXX
        </div>
      </div>
      <Footer />
    </>
  )
}
