import React from "react"
import Axios from "axios"

import Translate from "react-translate-component"

class EmailForm extends React.Component {
  state = {
    email: "",
  }

  submitEmail = () => {
    console.log(this.state.email)
    if (this.validateEmail()) {
      Axios.post("http://localhost:3003/api/create", {
        email: this.state.email,
      })
    } else console.log("Enter a correct email")
  }

  validateEmail() {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(this.state.email).toLowerCase())
  }

  render() {
    return (
      <div className="contact__form">
        <Translate
          content="contact.subscribe"
          component="h2"
          className="contact__subscribe title-2"
        />

        <form
          action="./phpScripts/subscribe.php"
          method="post"
          className="email-container"
          id="form"
          onsubmit="return validateEmail()"
        >
          <input
            type="email"
            name="email"
            id="email"
            placeholder="Email Address"
            onChange={(e) => {
              this.state.email = e.target.value
            }}
            required={true}
          />
          <label for="email" className="email-label">
            E-mail address
          </label>
        </form>

        <Translate
          // onClick={this.submitEmail}
          content="contact.signUp"
          component="button"
          className="sing_up btn btn-primary"
          type="submit"
          form="form"
        />
      </div>
    )
  }
}

export default EmailForm
