import React from "react"
import "./navMobile.css"

import { GiHamburgerMenu } from "react-icons/gi"
import LanguageSwitcher from "../LanguageSwitcher"

// Translate
import Translate from "react-translate-component"

import $ from "jquery"

class NavMobile extends React.Component {
  componentDidMount() {
    $("#openNav").on("click", function () {
      $("#myNav").css("width", "100%")
    })

    $(".exit").on("click", function () {
      $("#myNav").css("width", "0%")
    })
  }
  render() {
    return (
      <>
        <div id="myNav" class="overlay">
          <a class="closebtn exit" id="closeNav">
            x
          </a>
          <div class="overlay-content">
            <Translate content="header.links.l1" component="a" href="/" />
            <Translate
              content="header.links.l2"
              component="a"
              href="services"
            />
            <Translate content="header.links.l3" component="a" href="aboutUs" />
            <Translate content="header.links.l6" component="a" href="news" />
            <Translate content="header.links.l5" component="a" href="career" />
            <Translate
              content="header.links.l7"
              component="a"
              href="../online_certificate_home"
            />
            <LanguageSwitcher fn={this.props.fn} />
          </div>
        </div>

        <div class="fixed-action-btn">
          <a class="btn-lg hamburger" id="openNav">
            <GiHamburgerMenu />
          </a>
        </div>
      </>
    )
  }
}

export default NavMobile


