/* ----------------------------- Show on scroll ----------------------------- */
import $ from "jquery"

function observer() {
  const loopChild = (element) => {
    var children = element.children
    var all = []
    for (var i = 0; i < children.length; i++) {
      all.push(children[i])
    }
    return all
  }

  const options = { threshold: 0.1 }

  const appearOnScroll = new IntersectionObserver(function (entries, observer) {
    entries.forEach((entry) => {
      if (!entry.isIntersecting) {
        return
      } else {
        if (entry.target.classList.contains("appear-anim")) {
          $(entry.target).animate(
            {
              opacity: 1,
            },
            2000
          )
        } else if (
            entry.target.classList.contains("slide-right-anim") ||
          entry.target.classList.contains(
            "slide-left-anim" ||
              entry.target.classList.contains("slide-up-anim")
          )
        ) {
          $(entry.target).css("transform", "translate(0.0)")

        } else if (entry.target.classList.contains("cascade")) {
          var array = loopChild(entry.target)
          var i = 0
          var interval = setInterval(() => {
            const element = array[i]
            element.classList.add("fast-appear")
            i++
            if (i >= array.length) {
              clearInterval(interval)
            }
          }, 100)
        }
        observer.unobserve(entry.target)
      }
    })
  }, options)

  const targets = document.querySelectorAll(".show-on-scroll")
  targets.forEach(function (target) {
    appearOnScroll.observe(target)
  })
}

export default observer
