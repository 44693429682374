import React from "react"
import "./newsPage.css"

// Import component
import Header from "../../components/header/Header"
import Footer from "../../components/footer/Footer"
import News from "../../components/news/News"

class NewsPage extends React.Component {

  render() {
    return (
      <main className="newsPage">
        <Header />
        <News />
        <Footer />
      </main>
    )
  }
}

export default NewsPage
