import React from "react"


import "@splidejs/splide/dist/css/themes/splide-default.min.css"
import "@splidejs/splide/dist/css/themes/splide-skyblue.min.css"
import "@splidejs/splide/dist/css/themes/splide-sea-green.min.css"

import { Accordion } from "react-bootstrap-accordion"

// Translate
import Translate from "react-translate-component"

import CarouselArrow from "../../jsScripts/CarouselArrow"

class News extends React.Component {



  render() {
    const path = "images/news/news1/"
    const path2 = "images/news/news2/"
    const path3 = "images/news/news3/"
    const path4 = "images/news/news4/"
    const path5 = "images/news/news5/"
    const urls = [
      path + "p1.jpg",
      path + "p2.jpg",
      path + "p33.jpg",
      path + "4.webp",
      path + "5.jpg",
      path + "6.png",
      path + "7.jpg",
    ]
    const urls2 = [
      path2 + "1.jpg",
      path2 + "2.jpg",
      path2 + "3.jpg",
      path2 + "4.jpg",
      path2 + "5.jpg",
      path2 + "6.jpg",
      path2 + "7.jpg",
      path2 + "8.jpg",
      path2 + "9.jpg",
      path2 + "10.jpg",
      path2 + "11.jpg",
    ]
    const urls3 = [
      path3 + "1.jpg",
      path3 + "2.jpg",
      path3 + "3.gif",
      path3 + "4.gif",
      path3 + "5.jpg",
      path3 + "6.gif",
      path3 + "7.gif",
      path3 + "8.gif",
      path3 + "9.gif",
      path3 + "10.jpg",
      path3 + "11.jpg",
    ]
    const urls4 = [
      path4 + "1.jpg",
      path4 + "2.jpg",
      path4 + "3.jpg",
      path4 + "4.png",
      path4 + "5.png",
      path4 + "6.png",
    ]
    const urls5 = [
      path5 + "1.jpg",
      path5 + "2.jpg",
      path5 + "3.jpg",
      path5 + "4.jpg",
      path5 + "5.jpg",
      path5 + "6.jpg",
    ]

    return (
      // <div className="news show-on-scroll appear-anim">
      <div className="news" id="lastNews">
        <Translate
          content="news.title"
          component="h1"
          className="title text-center my-5 display-4"
        />

        {/* //! ---------------- 5------------------- */}
        <div className="newsContainer--5">
          <hr />

          <Translate
            content="news.title5"
            component="h1"
            className="text-success"
            unsafe={true}
          />
          <hr />
          <div className="newsContainer ">
            <CarouselArrow urls={urls5} id="news5" />

            <Translate
              content="news.text5"
              component="div"
              unsafe={true}
              className="news-text"
            />
          </div>
        </div>
        {/* //! ---------------- 4 ------------------- */}
        <div className="newsContainer--4">
          <hr />

          <Translate
            content="news.title4"
            component="h1"
            className="text-success"
            unsafe={true}
          />
          <hr />
          <div className="newsContainer ">
            <CarouselArrow urls={urls4} id="news4" />

            <Translate
              content="news.text4"
              component="div"
              unsafe={true}
              className="news-text"
            />
          </div>
        </div>
      {/* // /* ------------------------------------ 3 ----------------------------------- */ }
        <div className="newsContainer--3">
          <hr />

          <Translate
            content="news.title3"
            component="h1"
            className="text-success"
            unsafe={true}
          />
          <hr />
          <div className="newsContainer ">
            <CarouselArrow urls={urls3} id="news3" />

            <Translate
              content="news.text3"
              component="div"
              unsafe={true}
              className="news-text"
            />
          </div>
        </div>

        {/* /* ------------------------------------ 2 ----------------------------------- */ }

        <div className="newsContainer--2">
          <hr />
          <Translate
            content="news.title2"
            component="h1"
            className="text-success"
            unsafe={true}
          />

          <hr />

          <div className="newsContainer ">
            <CarouselArrow urls={urls2} id="news2" />

            <Translate
              content="news.text2"
              component="div"
              unsafe={true}
              className="news-text"
            />
          </div>
        </div>

        {/* /* ------------------------------------ 1 ----------------------------------- */ }
        <div className="newsContainer--1">
          <hr />

          <Translate
            content="news.title1"
            component="h1"
            className="text-success"
            unsafe={true}
          />
          <hr />

          <div className="newsContainer ">
            <CarouselArrow urls={urls} id={"news"} />

            <Translate content="news.text" component="div" unsafe={true} />
          </div>
        </div>
      </div>
    )
  }
}

export default News

// <video className="news_mySlides newsFade vid" autoPlay muted>
//             <source src="images/news/vid.MOV" type="video/mp4" />
//           </video>
