import React from "react"
import "./aboutUs.css"

import Header from "../../components/header/Header"
import Footer from "../../components/footer/Footer"

import observer from "../../jsScripts/observer"

// Translate
import counterpart from "counterpart"
import Translate from "react-translate-component"

import $ from "jquery"

class AboutUs extends React.Component {
  componentDidMount() {
    observer()
  }

  onLangChange = (language) => {
    this.setState({ lang: language })
    counterpart.setLocale(language)
    if (language == "ar") {
      $("#root").css({ "text-align": "right", direction: "rtl" })
    } else {
      $("#root").css("text-align", "left")
    }
  }

  render() {
    return (
      <main className="aboutus">
        <Header fn={this.onLangChange} modifier={"mod2"} />
        <div className="aboutUs__container mt-5">
          <div className="show-on-scroll slide-right-anim">
            <div>
              <img
                className="image m-5 float-right"
                src="./images/aboutUs/aboutUs2.jpg"
              />
              <Translate
                content="aboutUsPage.title1"
                component="h2"
                className="mb-3"
              />

              <Translate
                content="aboutUsPage.text1"
                component="p"
                className="mbvw-10"
                unsafe={true}
              />
            </div>
          </div>

          <div className="show-on-scroll slide-left-anim">
            <div>
              <img
                className="image m-5 float-left"
                src="./images/aboutUs/aboutUs3.jpg"
              />

              <Translate
                content="aboutUsPage.text2"
                component="p"
                className="mbvw-10"
                unsafe={true}
              />

              <Translate
                content="aboutUsPage.text3"
                component="p"
                className="content__text content__text--2 mbvw-5"
              />
            </div>
          </div>

          <Translate
            content="aboutUsPage.title2"
            component="h2"
            className="mb-3"
          />

          <Translate
            content="aboutUsPage.text4"
            component="p"
            className="aboutUs__paragraphe"
          />

          <ol className="mb-5">
            <Translate
              className="show-on-scroll cascade"
              content="aboutUsPage.list1"
              component="div"
              unsafe={true}
            />
            <Translate
              className="show-on-scroll cascade"
              content="aboutUsPage.list2"
              component="div"
              unsafe={true}
            />
          </ol>
          <Translate content="aboutUsPage.text5" component="p" unsafe={true} />
          <Translate
            content="aboutUsPage.list3"
            component="ul"
            className="aboutUs-languages"
            unsafe={true}
          />
        </div>
        <Footer />
      </main>
    )
  }
}

export default AboutUs
