import React from "react"
// import "../App.css"
import "./contact.css"

import Header from "../../components/header/Header"
import Footer from "../../components/footer/Footer"

import observer from "../../jsScripts/observer"

import $ from "jquery"

class Contact extends React.Component {
  componentDidMount() {
    observer()
  }

  render() {
    return (
      <main className="contact-page">
        <Header />
        <div className="aboutUs__container"></div>

        <Footer />
      </main>
    )
  }
}

export default Contact
