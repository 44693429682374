import React from "react"
import "./App.css"

import { BrowserRouter as Router, Switch, Route, Link, Redirect } from "react-router-dom"

import MainPage from "./pages"
import NotFound from "./pages/404"
import AboutUs from "./pages/aboutUs/aboutUs"
import Services from "./pages/services/Services"
import Career from "./pages/career/Career"
import Contact from "./pages/contact/Contact"
import NewsPage from "./pages/news/NewsPage"
import observer from "./jsScripts/observer"
import SuccessPage from "./pages/success/SuccessPage"
import OnlineCourses from "./pages/OnlineCourses/OnlineCourses"
import Overview from "./pages/OnlineCourses/overview/Overview"
import Lecturer from "./pages/career/description/Lecturer"
import Home from "./pages/OnlineCourses/home/Home"
import Payment from "./pages/OnlineCourses/overview/payment/Payment"

class App extends React.Component {

  componentDidMount() {
    observer()
  }


  render() {
    const urls = [
      "facilities management",
      "food and beverage management",
      "career management",
      "innovation management",
      "cross-cultural management",
      "trends and innovation in hospitality",
      "hospitality tourism business project",
      "international business protocol",
      "event management and operation",
      "food and beverage services",
      "hygiene, safety & nutrition",
      "hospitality economics",
    ]

    const jobs_description = [
      "lecturer",
      "regional_manager",
      "food_beverage",
    ]


    return (
      <Router>
        <Switch>
          <Route exact path="/" component={MainPage} />
          <Route exact path="/404" component={NotFound} />
          <Route exact path="/aboutUs" component={AboutUs} />
          <Route exact path="/services" component={Services} />

          {/* Career */}
          <Route exact path="/career" component={Career} />
          {jobs_description.map((url, i) => (
            <Route
              exact
              path={"/career/" + url}
              render={(props) => <Lecturer id={url} {...props} />}
            />
          ))}

          <Route exact path="/contact" component={Contact} />
          <Route exact path="/news" component={NewsPage} />
          <Route exact path="/success" component={SuccessPage} />
          {/* Online certificate */}
          <Route exact path="/online_certificate_home" component={Home} />
          <Route exact path="/online_certificate" component={OnlineCourses} />
          {/* Payment */}
          <Route exact path="/online_certificate/payment" component={Payment} />

          {/* Online certificate overview */}
          {urls.map((url, i) => (
            <Route
              exact
              path={"/online_certificate/" + url}
              render={(props) => <Overview id={i} {...props} />}
            />
          ))}

          <Redirect to="/404" />
        </Switch>
      </Router>
    )
  }
}

export default App
