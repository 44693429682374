import React from "react"
import "./job2.css"

import Translate from "react-translate-component"




class Job2 extends React.Component {
  render() {
    var x = this.props.id
    
    return (
      <div className="jobCard m-5">
        {/* <Translate
          content={"career.jobPost.hourly"}
          className="hourly badge-pill badge-success"
        /> */}
        <Translate
          content={"career.jobPost.job" + x + ".time"}
          className="time"
          component="h5"
        />
        <Translate
          content={"career.jobPost.job" + x + ".title"}
          className="text-center mb-3 job-title"
          component="h4"
          unsafe={true}
        />
        {/* <Translate
          content={"career.jobPost.job" + x + ".tags"}
          className="text-center d-flex mb-3"
          component="ul"
          unsafe={true}
        /> */}

        <Translate
          content={"career.jobPost.job" + x + ".text"}
          className=""
          component="p"
          unsafe={true}
        />
        <Translate
          content={"career.jobPost.job" + x + ".location"}
          className="location"
          component="h6"
        />
        <Translate
          content={"career.jobPost.apply"}
          className="btn applyBtn job-js"
          component="a"
          href="#apply"
          id={this.props.id - 1}
        />
      </div>
    )
  }
}

export default Job2
