import React from "react"
import "./inputText.css"

// Translate
import counterpart from 'counterpart'
import Translate from 'react-translate-component'

class InputText extends React.Component {
  render() {
    var type = this.props.name == "email" ? "email" : "text"
    var patterns = this.props.name != "email" ? { ['pattern']: "[A-Za-z]+" } : null
    return (
      <>
        {this.props.label}
        <div class="omrs-input-group">
          <label class="omrs-input-underlined">
            <input
              type={type}
              required
              name={this.props.name}
              placeholder=" "
              {...patterns}
            />
            <span class="omrs-input-label">{this.props.placeholder}</span>
          </label>
        </div>
      </>
    )
  }
}

export default InputText
