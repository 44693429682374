import React from "react"

class NotFound extends React.Component {
  render() {
    return (
      <div>
        <h3>Page not Found</h3>
      </div>
    )
  }
}

export default NotFound
