import React from "react"
// import "./popup.css"

import $ from "jquery"

import { AiOutlineMail } from "react-icons/ai"

class PopUp extends React.Component {
  componentDidMount() {
    $("#click-me").click(function () {
      $("#show").toggle("slow", function () {})
      // $("#show").toggleClass("hidden")
    })

    $(".x").click(function () {
      $("#show").toggle("slow", function () {})
      // $(".container").toggleClass("hidden")
    })
  }

  render() {
    return (
      <div>
        <button className="contact_us" id="click-me">
          Contact Us
        </button>
        <div id="show">
          <div className="container">
            <div className="mail_icon">
              <AiOutlineMail />
            </div>
            <div>
              <div className="x">
                <div className="one"></div>
                <div className="two"></div>
              </div>
              <div className="sign-up">
                <h1>Join Us</h1>
                <p className="sub-head">
                  Be the first to receive our exclusive copy of ...
                </p>
                <form>
                  <input
                    type="text"
                    name="email"
                    placeholder="Enter your email address"
                  />
                  <input type="submit" value="Contact Us" />
                </form>
                {/* <div className="nothanks">
                  <a href="#">No Thanks</a>
                </div>
                <p className="nospam">
                  We promise to never smap your inbox. Offer is only valid for a
                  limited time. Don't miss on this awesome chance to gain
                  knowledge from one of the best designers. For more info please
                  read the <span>Terms and Services</span>.
                </p> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default PopUp
