import React from "react"
import "../text.css"

import Translate from "react-translate-component"

function Text() {
  return (
    <section class="section text-container">
      <div class="content__container show-on-scroll slide-right-anim">
        <Translate
          content="welcomeText.text1"
          component="p"
          className="content__text content__text--1"
          unsafe={true}
        />
        <Translate
          content="welcomeText.title1"
          component="h3"
          className="content__title"
          unsafe={true}
        />
        <Translate
          content="welcomeText.text2"
          component="p"
          className="content__text"
          unsafe={true}
        />
      </div>
      <img
        className="logoAffiche show-on-scroll slide-left-anim"
        src="images/logo/logoAffiche.png"
        alt=""
      />
    </section>
  )
}

export default Text
