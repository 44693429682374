import React from 'react'

import $ from "jquery"
import arabicAlign from '../../jsScripts/arabicAlign'
import counterpart from 'counterpart'


class LanguageSwitcher extends React.Component {
  componentDidMount() {
    $(".exit-js").on("click", function () {
      $("#myNav").css("width", "0%")
    })
  }

  handleChange = (language) => {
      // this.setState({ lang: language })
      counterpart.setLocale(language)
    arabicAlign(language)
    setTimeout(() => {
      
      $(".aboutus li").css("opacity", "1")
    }, 100);
    
    // this.props.fn(language)
  }
  render() {
    return (
      <ul className="language-list exit-js" id={this.props.id}>
        <li>
          <input
            type="radio"
            name="langRadio"
            className="is-active"
            id="en"
            onClick={() => this.handleChange("en")}
          />
          <label htmlFor="en">En</label>
        </li>
        <li>
          <input
            type="radio"
            name="langRadio"
            id="de"
            onClick={() => this.handleChange("de")}
          />{" "}
          <label htmlFor="de">De</label>
        </li>
        <li>
          <input
            type="radio"
            name="langRadio"
            id="fr"
            onClick={() => this.handleChange("fr")}
          />{" "}
          <label htmlFor="fr">Fr</label>
        </li>
        <li>
          <input
            type="radio"
            name="langRadio"
            id="ar"
            onClick={() => this.handleChange("ar")}
          />{" "}
          <label htmlFor="ar">عربي</label>
        </li>
      </ul>
    )
  }
}
 
export default LanguageSwitcher