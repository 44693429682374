import React from "react"

import Translate from "react-translate-component"

class ServicesText extends React.Component {
  render() {
    var x = this.props.id
    const className =
      "mx-auto border border-dark mb-5 p-5" +
      (x % 2
        ? " show-on-scroll slide-right-anim"
        : " show-on-scroll slide-left-anim")
    return (
      <div id={"link" + x} className={className}>
        <Translate
          content={"servicesCards" + x + ".card_title"}
          component="h3"
          unsafe={true}
          className="mb-3 text-center text-primary text-center"
        />
        <Translate
          content={"servicesCards" + x + ".card_text"}
          component="p"
          unsafe={true}
          className="text-justify text-center"
        />
      </div>
    )
  }
}

export default ServicesText
