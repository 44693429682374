import React from "react"
import "./OnlineCourses.css"

import Header from "../../components/header/Header"
import Footer from "../../components/footer/Footer"

import { HiCursorClick } from "react-icons/hi"

// Translate
import Translate from "react-translate-component"
import en from "../locales/en"

class Contact extends React.Component {
  render() {
    const urls = [
      "facilities management",
      "food and beverage management",
      "career management",
      "innovation management",
      "cross-cultural management",
      "trends and innovation in hospitality",
      "hospitality tourism business project",
      "international business protocol",
      "event management and operation",
      "food and beverage services",
      "hygiene, safety & nutrition",
      "hospitality economics",
    ]
    return (
      <main className="onlineCourses-page">
        <Header modifier="mod2" fn={this.onLangChange} />
        <div className="onlineCourses_container">
          <Translate
            content="overviewPage.title"
            component="h1"
            className="text-center mb-5"
          />
          <Translate
            content="overviewPage.text"
            component="h5"
            unsafe={true}
            className="text-center mb-5"
          />

          {/* Categories */}
          <div className="courses_container">
            {urls.map((url, i) => (
              <div className="onlineCourses_container_category">
                {/* Title */}
                <h2>
                  <Translate
                    content={"overviewPage.cat" + i + ".title"}
                    component="a"
                    className="mb-3"
                    unsafe={true}
                    href={"/online_certificate/" + url}
                  />
                </h2>

                {/* Image */}
                <div className="image_container">
                  <a href={"/online_certificate/" + url}>
                    <img
                      src={"images/online courses/online-courses" + i + ".jpg"}
                      className=""
                    />
                  </a>
                  <HiCursorClick className="click" />
                </div>
                {/* List of topics */}
                {/* <Translate
                    content={"overviewPage.cat" + i + ".topics"}
                    component="ul"
                    className="pl-3 topics_list"
                    unsafe={true}
                  /> */}
              </div>
            ))}
          </div>

          {/* <Translate content="overviewPage.cat0.title" component="h2" />

          <div className="topics_container">
            {Array.from({ length: 5 }, (_, i) => (
              <Translate
                content={"overviewPage.cat1.topic" + i}
                component="a"
                href="/online_certificate/1"
              />
            ))}
          </div>
          <Translate content="overviewPage.cat1.title" component="h2" />

          <div className="topics_container">
            {Array.from({ length: 5 }, (_, i) => (
              <Translate
                content={"overviewPage.cat1.topic" + i}
                component="a"
                href="/online_certificate/2"
              />
            ))}
          </div>

          <Translate content="overviewPage.cat2.title" component="h2" />

          <div className="topics_container">
            {Array.from({ length: 2 }, (_, i) => (
              <Translate
                content={"overviewPage.cat2.topic" + i}
                component="a"
                href="/online_certificate/2"
              />
            ))}
          </div> */}
        </div>

        <Footer />
      </main>
    )
  }
}

export default Contact
