import React from 'react';
import './PopupForm.css'

import $ from 'jquery'

// Translate
import Translate from 'react-translate-component'

import InputText from "./inputText/InputText"
import Captcha from '../../captcha/Captcha'

class PopupForm extends React.Component {

  captcha(e) {
    e.preventDefault()

    $(".wrapper").toggle()

  }
  render() {
    const inputs = ["First Name", "Last Name", "Email"]
    const names = ["firstName", "lastName", "email"]
    const select = [
      "International Business Protocol",
      "General Hospitality and Tourism",
      "Customer Services.",
      "Food and Beverage Services",
      "F&B (Cost Control,Banqueting and Catering)",
      "Event Management and Operations",
      "Hospitality Economics",
      "Hospitality Marketing and Sales(Direct Selling and CustomerService)",
      "Communication Skills",
      "Luxury Brand Management in Hospitality",
      "Project Management",
      "Service Operation Management",
      "Resort and Destination Management",
      "Hospitality/Tourism Student Business Projects",
      "Cross Cultural Management",
      "Marketing, Tourism, and Strategy",
    ]
    return (
      <div className="displayFormJS">
        <Captcha />
        <div className="offersDiv-1">
          <form
            className="popupOffers__form"
            onSubmit={this.captcha}
            id="offersForm"
            action="phpScripts/offers.php"
            method="post"
          >
            {Array.from({ length: 3 }, (_, i) => (
              <InputText
                label={
                  <Translate
                    content={"popup.formLabel" + i}
                    component="label"
                  />
                }
                placeholder={
                  <Translate
                    content={"popup.formLabel" + i}
                    component="span"
                    className="omrs-input-label"
                  />
                }
                name={names[i]}
                key={i}
              />
            ))}
            <Translate content="popup.formLabel3" component="label" />

            <select required name="course">
              {select.map((val) => (
                <option value={val}> {val} </option>
              ))}
            </select>
          </form>

          <Translate
            content="popup.formText"
            component="h6"
            unsafe={true}
            className="text-center mb-3"
          />

          <Translate
            content="popup.submit"
            component="button"
            unsafe={true}
            form="offersForm"
            className="btn btn-primary submitJS"
            type="submit"
          />
        </div>
      </div>
    )
  }
}
 
export default PopupForm;