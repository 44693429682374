import React from "react"
import "./prevColab.css"

import Translate from "react-translate-component"


function PrevColab() {
  return (
    <section class="previous-cola">
      <Translate
        content="prevColab.title"
        component="h1"
        className="prev__title"
        unsafe={true}
      />

      <Translate
        content="prevColab.text"
        component="p"
        className="prev__text"
        unsafe={true}
      />
    </section>
  )
}

export default PrevColab
