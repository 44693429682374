import React from "react"
import "./overview.css"
import {
  AiOutlineCalendar,
  AiOutlineClockCircle,
  AiOutlineUser,
  AiOutlineLaptop,
} from "react-icons/ai"
import { RiTimerFlashLine } from "react-icons/ri"
import { BsGraphUp } from "react-icons/bs"
import Header from "../../../components/header/Header"
import Footer from "../../../components/footer/Footer"

import {Link} from 'react-router-dom';

import en from "../../locales/en"

// Translate
import Translate from "react-translate-component"

import $ from "jquery"

import { AiOutlineArrowLeft } from "react-icons/ai"

class Overview extends React.Component {
  componentDidMount() {
    $(".nidhal_learn_more-js").click(function () {
      $(".nidhal_bio-js").toggle()
      $(".anna_bio-js").hide()
    })
    $(".anna_learn_more-js").click(function () {
      $(".anna_bio-js").toggle()
      $(".nidhal_bio-js").hide()
    })

    //  Sticky Side nav
    window.onscroll = function () {
      stickySideNav()
    }

    var footer = document.getElementsByClassName("footer")[0]
    var sideNav = document.getElementsByClassName("side_nav")[0]
    var sticky = footer.offsetTop
    var viewportHeight = window.innerHeight

    function stickySideNav() {
      if (window.pageYOffset + viewportHeight > sticky) {
        sideNav.classList.add("sticky1")
      } else {
        sideNav.classList.remove("sticky1")
      }
    }
  }

  render() {
    const i = this.props.id

    const data2 = [
      <AiOutlineCalendar size="3.5rem" />,
      <AiOutlineClockCircle size="3.5rem" />,
      <AiOutlineUser size="3.5rem" />,
      <RiTimerFlashLine size="3.5rem" />,
      <AiOutlineLaptop size="3.5rem" />,
      <BsGraphUp size="3.5rem" />,
    ]

    return (
      <div className="overview">
        <Header modifier="mod2" fn={this.onLangChange} />

        {/* Main Container */}
        <div className="overview_container">
          {/* Side nav and Payment*/}
          <div className="side_nav">
            <div className="side_nav_container">
              <Translate
                content="overview.nav0"
                component="a"
                href="/online_certificate"
              />
              <hr />
              <Translate
                content="overview.title5"
                component="a"
                href="#title1"
              />
              <hr />
              <Translate
                content="overview.title6"
                component="a"
                href="#title2"
              />
              <hr />
              <Translate
                content="overview.listOfTopics"
                component="a"
                href="#title3"
              />
              <hr />
              <Translate
                content="overview.title2"
                component="a"
                href="#title4"
              />
              <hr />
              <Translate
                content="overview.title4"
                component="a"
                href="#title5"
              />
            </div>
            {/* Price */}

            <Link
              to={{
                pathname: "/online_certificate/payment",
                state: { name: en.overviewPage["cat" +i ].title },
              }}
            >
              <Translate
                content="overview.price"
                component="a"
                className="price"
                unsafe={true}
              />
            </Link>
          </div>
          {/* Category title */}
          <Translate
            content={"overviewPage.cat" + i + ".title"}
            component="h1"
            className="text-center my-5 display-3 text-danger"
          />
          {/* Title */}
          <Translate
            content={"overview.title"}
            component="h1"
            className="text-center"
          />
          {/*Sub Title 1*/}
          <div id="title1"></div>
          <Translate
            content={"overview.title5"}
            component="h2"
            className="text-center mt-5"
          />
          <Translate
            content={"overviewPage.cat" + i + ".text1"}
            component="p"
            className="text-center"
          />
          {/*Sub Title 2 */}
          <div id="title2"></div>

          <Translate
            content={"overview.title6"}
            component="h2"
            className="text-center mt-5"
          />
          <Translate
            content={"overviewPage.cat" + i + ".text2"}
            component="p"
            className="text-center"
          />
          {/* Details drop down */}
          {/* <Translate content="overview1.text" component="p" unsafe={true} />
          <ul>
            {Array.from({ length: 3 }, (_, i) => (
              <details>
                <Translate
                  content={path + ".summary" + i}
                  component="summary"
                  unsafe={true}
                />
                <Translate
                  content={path + ".summaryT" + i}
                  component="summary"
                  unsafe={true}
                />
              </details>
            ))}
          </ul> */}

          {/* List of topics */}
          <div id="title3"></div>

          <Translate
            content={"overview.listOfTopics"}
            component="h2"
            className="text-center mt-5"
          />
          <Translate
            content={"overviewPage.cat" + i + ".topics"}
            component="ol"
            className="pl-3 topics_list"
            unsafe={true}
          />

          {/* How it works */}
          <div id="title4"></div>

          <Translate
            content={"overview.title2"}
            component="h2"
            className="text-center m-5"
          />
          <div className="overview_infos">
            {data2.map((elem, i) => (
              <div className="flex-column text-center">
                {elem}
                <Translate
                  content={"overview.info" + i}
                  component="h4"
                  className="mt-1"
                />
                <Translate content={"overview.infoSub" + i} component="small" />
              </div>
            ))}
          </div>

          {/* FACULTY AUTHOR */}
          <div id="title5"></div>

          <Translate
            content={"overview.title4"}
            component="h2"
            className="text-center m-5"
          />
          <div className="d-flex justify-content-around faculty_container">
            {/* Nidhal Image */}

            <div>
              <div className="faculty_image">
                <img src="../images/nidhal.jpg" alt="" />
                <a href="#bio_anchor" className="nidhal_learn_more-js">
                  Learn more
                </a>
              </div>

              <Translate
                content={"overview.nidhalName"}
                component="h3"
                className="text-center m-3"
              />
              <Translate
                content={"overview.nidhal_title"}
                component="h6"
                className="text-center text-danger font-weight-bold"
              />
              <Translate
                content={"overview.nidhal_Subtitle"}
                component="h6"
                className="text-center text-secondary font-weight-bold"
              />
            </div>

            {/* Anna Shmit */}
            <div>
              <div className="faculty_image">
                <img src="../images/Anna Shmit.jpg" alt="Anna Shmit" />
                <a href="#bio_anchor" className="anna_learn_more-js">
                  Learn more
                </a>
              </div>

              <Translate
                content={"overview.annaName"}
                component="h3"
                className="text-center m-3"
                id="bio_anchor"
              />
              <Translate
                content={"overview.anna_title"}
                component="h6"
                className="text-center text-danger font-weight-bold"
              />
              <Translate
                content={"overview.nidhal_Subtitle"}
                component="h6"
                className="text-center text-secondary font-weight-bold"
              />
            </div>
          </div>
          {/* Bios */}
          <Translate
            content="overview.nidhal_bio"
            component="div"
            className="nidhal_bio-js box-hidden"
            unsafe={true}
          />
          <Translate
            content="overview.anna_bio"
            component="div"
            className="anna_bio-js box-hidden"
            unsafe={true}
          />

          {/* Details */}
          {/* <div className="overview_key my-5 ">
            <div class="div1">
              <Translate
                content={path + ".title3"}
                component="h2"
                className="text-center m-5"
              />

              <ul>
                {data.overview.key.map((elem) => (
                  <li>{elem}</li>
                ))}
              </ul>
            </div>
            <img src="../images/overview/laptop.png" />

            <img src="../images/logo/logoAffiche.png" className="w-100" />

            <div class="div3">
              <h2 className="text-center m-5">WHAT YOU'LL EARN</h2>

              <ul>
                {data4.map((elem) => (
                  <li>{elem}</li>
                ))}
              </ul>
            </div>
          </div>

          <h2 className="text-center m-5">WHO SHOULD ENROLL</h2>

          <ul className="text-center show-on-scroll cascade">
            {data5.map((elem) => (
              <li className="my-5">{elem}</li>
            ))}
          </ul> */}
        </div>

        <Footer />
      </div>
    )
  }
}

export default Overview
