import React, { useRef } from "react"
import "./popupOffers.css"

import "@splidejs/splide/dist/css/themes/splide-default.min.css"
import "@splidejs/splide/dist/css/themes/splide-skyblue.min.css"
import "@splidejs/splide/dist/css/themes/splide-sea-green.min.css"

// Translate
import Translate from "react-translate-component"

import $ from "jquery"
import CarouselArrow from "../../jsScripts/CarouselArrow"
import showOrHide from "../../jsScripts/showOrHide"
import PopupForm from "./popupForm/PopupForm"
import LanguageSwitcher from "../header/LanguageSwitcher"

class PopupOffers extends React.Component {
  constructor(props) {
    super(props)
    this.myRef = React.createRef()
  }
  componentDidMount() {
    var popup = $(".popupOffers")

    // Display after seconds
      setTimeout(() => {
        showOrHide(".popupOffers")
        // popup.css("opacity", "100")
      }, 15000)

    // Close
    $(".x_popup").click(function () {
      popup.toggle()
    })
    $(".x_certificate_showcase").click(function () {
      $(".certificate_showcase").toggle()
    })

    // Toggle form
    $(".enrollJS").click(function () {
      $(".displayFormJS").toggle()
      $(".offersDiv-js").toggle()
      checkMediaQuery(x)
    })

    // Toggle showcase mobile
    $(".view_certificate").click(function () {
      showOrHide(document.getElementById("popup_offer3"))
      $(".offers_text-js").toggle()
    })

    function checkMediaQuery(x) {
      if (x.matches) {
        $(".offersTitle").toggle()
      }
    }

    var x = window.matchMedia("(max-width: 767px)")
    checkMediaQuery(x)
    x.addListener(checkMediaQuery)
  }

  render() {
    const path = "images/popup/"
    var carouselUrl = [
      path + "ilustration.png",
      path + "PopupCertificate.png",
      path + "PopupCertificate2.png",
    ]
    var carouselUrlMobile = [
      path + "PopupCertificate.png",
      path + "PopupCertificate2.png",
    ]
    return (
      <div
        className="popupOffers box-hidden"
        style={{
          backgroundImage: "url(images/bg/waves1.png)",
        }}
      >
        {/* Close */}
        <div className="x x_popup">
          <div className="one"></div>
          <div className="two"></div>
        </div>

        {/* Language Switch */}
        <LanguageSwitcher id="popupLanguageSwitcher" />

        <Translate
          content="popup.title"
          component="h1"
          className="text-center text-danger offersTitle"
        />

        <div className="popupCenterGrid offersDiv-js">
          <div className="carousel_container">
            <CarouselArrow
              urls={carouselUrl}
              id={"popup_offer1"}
              classes="popup_offer_carousel"
            />
          </div>

          <div className="offersDiv ">
            <CarouselArrow
              urls={carouselUrlMobile}
              id="popup_offer3"
              classes="popup_offer_carousel box-hidden"
              interval={3000}
            />
            <div className="offers_text-js">
              <Translate
                content="popup.subTitle1"
                component="h4"
                className="text-center d-block"
                unsafe={true}
              />

              <Translate
                content="popup.subTitle2"
                component="h4"
                className="text-center d-block"
                unsafe={true}
              />
            </div>
          </div>
          <input type="checkbox" name="" id="view_certificate" hidden />

          <Translate
            content="popup.viewCertificate"
            component="label"
            className="view_certificate text-center gc-3 coloredText p-2 rounded"
            unsafe={true}
            htmlFor="view_certificate"
          />
          <Translate
            content="popup.enroll"
            component="button"
            className="btn btn-primary enrollJS"
          />
        </div>

        <PopupForm />
      </div>
    )
  }
}

export default PopupOffers
