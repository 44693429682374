import React from 'react';
import './lecturer.css'

// Translate
import Translate from 'react-translate-component'
import Header from '../../../components/header/Header'
import Footer from '../../../components/footer/Footer'

import { AiOutlineArrowLeft } from "react-icons/ai"

class lecturer extends React.Component {
  render() { 
    return (
      <>
        <Header modifier="mod2" />
        <div className="d-flex align-items-center pt-3 pl-3 gap-3">

        <AiOutlineArrowLeft size="2rem"/>
        <Translate
          content="job_description.goback"
          component="a"
          className="goBack"
          unsafe={true}
          href="/career/"
        />
        </div>
        <div className="job_description">
          <Translate
            content={"job_description." + this.props.id + ".title"}
            component="h1"
            className=""
            unsafe={true}
          />
          <Translate
            content={"job_description." + this.props.id + ".text"}
            component="p"
            className=""
            unsafe={true}
          />
        </div>
        <Footer />
      </>
    )
  }
}
 
export default lecturer;