import React from "react"
import "./header.css"

import MobileNav from "./mobileNav/NavMobile"

import Translate from "react-translate-component"
import LanguageSwitcher from "./LanguageSwitcher"

class Header extends React.Component {
  componentDidMount() {
    //  Sticky header
    window.onscroll = function () {
      stickyHeader()
    }

    var header = document.getElementsByClassName("header")[0]
    var sticky = header.offsetTop

    function stickyHeader(x) {
      if (window.pageYOffset > sticky) {
        header.classList.add("sticky")
      } else {
        header.classList.remove("sticky")
      }
    }
  }

  render() {
    return (
      <header className={"header " + this.props.modifier}>
        <a href="/" className="logo">
          <div
            className="logo_icon"
            style={{ backgroundImage: `url("/images/logo/logologo.svg")` }}
          ></div>

          <Translate
            content="header.title"
            component="h1"
            className="logo__title"
          />
        </a>

        <ul className="nav">
          <li>
            <Translate content="header.links.l1" component="a" href="/" />
          </li>
          <li>
            <Translate
              content="header.links.l2"
              component="a"
              href="../services"
              className="services-link"
            />
          </li>
          <li>
            <Translate
              content="header.links.l3"
              component="a"
              href="../aboutUs"
              className="aboutUs-link"
            />
          </li>
          <li>
            <Translate
              content="header.links.l6"
              component="a"
              href="../news"
              className="news-link"
            />
          </li>
          <li>
            <Translate
              content="header.links.l5"
              component="a"
              href="../career"
              className="career-link"
            />
          </li>
          <li>
            <Translate
              content="header.links.l7"
              component="a"
              href="../online_certificate_home"
              className="online_certificate-link"
            />
          </li>
        </ul>
        <LanguageSwitcher fn={this.props.fn} />

        <MobileNav fn={this.props.fn} />
      </header>
    )
  }
}

export default Header
