import React from "react"
import "../App.css"
import "../animation.css"


import Header from "../components/header/Header"
import Hero from "../components/hero/Hero"
import Text from "../components/text/Text"
import TextImg from "../components/textImg/TextImg"
import PrevColab from "../components/prevColab/PrevColab"
import Contact from "../components/contact/Contact"
import Partners from "../components/partners/Partners"
import Footer from "../components/footer/Footer"
import PopupOffers from "../components/popupOffers/PopupOffers"

import observer from "../jsScripts/observer"
import arabicAlign from "../jsScripts/arabicAlign"

// Translate
import en from "./locales/en"
import de from "./locales/de"
import fr from "./locales/fr"
import ar from "./locales/ar"

import counterpart from "counterpart"

import News from "../components/news/News"

counterpart.registerTranslations("en", en)
counterpart.registerTranslations("de", de)
counterpart.registerTranslations("ar", ar)
counterpart.registerTranslations("fr", fr)
counterpart.setLocale("en")


class App extends React.Component {
  state = {
    lang: "en",
  }
  componentDidMount() {
    observer()
  }

  onLangChange = (language) => {
    this.setState({ lang: language })
    counterpart.setLocale(language)
    arabicAlign(language)
  }

  render() {
    return (
      <main className="index">
        <div className="welcome_page" id="home">
          <div className="slideshow-container">
            <img className="mySlides fadeSlides" src="images/mainPage/b7.jpg" />
            <img className="mySlides fadeSlides" src="images/mainPage/b2.jpg" />
            <img className="mySlides fadeSlides" src="images/mainPage/b1.jpg" />
          </div>

          <Header fn={this.onLangChange} />
          <Hero />
          <PopupOffers />
        </div>
        <News />
        <Text />
        <Contact />
        <TextImg />
        <PrevColab />
        <Partners />
        <Footer />
        <script
          src="https://maps.googleapis.com/maps/api/js?key=AIzaSyBFSV6obc7wdTl8Wln2ibMlVNRTfWv5Qm4&callback=initMap&libraries=&v =weekly"
          async
        ></script>
      </main>
    )
  }
}

export default App
